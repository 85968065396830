import React from 'react';

import './index.css';

function Name() {
  return (
    <div className="Name">
      Eli Cooper
    </div>
  );
}

export default Name;